<template>
  <router-view v-slot="{ Component }">
    <transition name="slide-fade" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "RenderChildrenLayout",
});
</script>

<style scoped></style>
