<template>
  <nav id="sidebarMenu" class="sidebar d-md-block bg-gradient-github text-white collapse">
    <div class="sidebar-inner px-4 pt-3">
      <div
        class="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4"
      >
        <div class="d-flex align-items-center">
          <div class="user-avatar lg-avatar me-4">
            <img
              src="@/assets/logo_mini.png"
              class="card-img-top rounded-circle border-white"
              alt="Astropay Image"
            />
          </div>
          <div class="d-block">
            <h2 class="h6">Hi, {{ store.getters.profile?.first_name }}</h2>
            <a
              href="javascript:void(0)"
              class="btn btn-secondary text-dark btn-xs"
              @click="LogoutUser"
            >
              <span class="mr-2">
                <span class="fas fa-sign-out-alt"></span>
              </span>Sign Out
            </a>
          </div>
        </div>
        <div class="collapse-close d-md-none">
          <a
            ref="BTN_closeNavigation"
            href="#sidebarMenu"
            class="fas fa-times"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          ></a>
        </div>
      </div>
      <ul class="nav flex-column">
        <template v-for="(item, index) in sideBarItems" :key="index">
          <li class="nav-item" :class="route.fullPath === item.path ? 'active' : ''">
            <template v-if="item.type === 'router'">
              <router-link :to="item.path" class="nav-link">
                <span v-if="item.icon" class="sidebar-icon">
                  <span :class="item.icon"></span>
                </span>
                <span>{{ item.name }}</span>
              </router-link>
            </template>
            <template v-else-if="item.type === 'nested'">
              <span
                class="nav-link collapsed d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                :data-bs-target="`#s_${index}_target`"
              >
                <span>
                  <span v-if="item.icon" class="sidebar-icon">
                    <span :class="item.icon"></span>
                  </span>
                  {{ item.name }}
                </span>
                <span class="link-arrow">
                  <span class="fas fa-chevron-right"></span>
                </span>
              </span>
              <div
                class="multi-level collapse"
                role="list"
                :id="`s_${index}_target`"
                aria-expanded="false"
              >
                <ul class="flex-column nav">
                  <template v-for="(child, index) in item.children" :key="index">
                    <li class="nav-item" :class="child.path === route.fullPath ? 'active' : ''">
                      <router-link class="nav-link" :to="child.path">
                        <span>{{ child.name }}</span>
                      </router-link>
                    </li>
                  </template>
                </ul>
              </div>
            </template>
          </li>
        </template>
        <li role="separator" class="dropdown-divider mt-4 mb-3 border-black"></li>
        <li v-admin="100" class="nav-item">
          <router-link to="/financial-audit" class="nav-link d-flex align-items-center">
            <span class="sidebar-icon">
              <span class="fas fa-briefcase"></span>
            </span>
            <span>Financial Audit</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/profile/2fa" class="nav-link d-flex align-items-center">
            <span class="sidebar-icon">
              <span class="fas fa-fingerprint"></span>
            </span>
            <span>2FAuthentication</span>
          </router-link>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link d-flex align-items-center" @click="LockUser">
            <span class="sidebar-icon">
              <span class="fas fa-lock"></span>
            </span>
            <span>Lock application</span>
          </a>
        </li>
        <li class="nav-item">
          <router-link to="/" class="nav-link d-flex align-items-center">
            <span class="sidebar-icon">
              <img src="@/assets/logo_mini.png" height="20" width="20" alt="Paidmoolah Logo" />
            </span>
            <span>Go back home</span>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ref, watch, defineComponent } from "vue";
import { UserActionTypes } from "@/store/modules/user/actions";

export default defineComponent({
  name: "DashboardSideBar",
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const sideBarItems = ref([
      {
        path: "/overview",
        type: "router",
        name: "Overview",
        icon: "fas fa-chart-pie",
      },
      {
        path: "/users",
        type: "router",
        name: "Users",
        icon: "fas fa-user",
      },
      {
        path: "/withdrawals",
        type: "router",
        name: "Withdrawals",
        icon: "fas fa-credit-card",
      },
      {
        path: "/notifications",
        type: "router",
        name: "Notifications",
        icon: "fas fa-bell",
      },
      {
        path: "",
        type: "nested",
        name: "Gift Card",
        icon: "fab fa-cc-amex",
        children: [
          {
            path: "/cards/available",
            name: "Available",
          },
          {
            path: "/cards/buy-t",
            name: "Buy Gift Card",
          },
          {
            path: "/cards/sell-t",
            name: "Sell Gift Card",
          },
        ],
      },
      {
        path: "",
        type: "nested",
        name: "Coins",
        icon: "fab fa-bitcoin",
        children: [
          {
            path: "/coins/coin-types",
            name: "Available Coins",
          },
          {
            path: "/coins/sell-coins",
            name: "Sell Coins",
          },
          {
            path: "/coins/coin-rates",
            name: "Coin Rates",
          },
        ],
      },
      {
        path: "",
        type: "nested",
        name: "Currency",
        icon: "fas fa-hand-holding-usd",
        children: [
          {
            path: "/currency/fiat",
            name: "Fiat Currencies",
          },
        ],
      },
      {
        path: "",
        type: "nested",
        name: "Settings",
        icon: "fas fa-cogs",
        children: [
          {
            path: "/settings/cards",
            name: "Cards Settings",
          },
          {
            path: "/settings/kyc",
            name: "KYC Settings",
          },
          {
            path: "/settings/withdrawals",
            name: "Withdrawal Settings",
          },
          {
            path: "/settings/responses",
            name: "Response Settings",
          },
        ],
      },
      {
        path: "",
        type: "nested",
        name: "Activities",
        icon: "fas fa-history",
        children: [
          {
            path: "/activities/activities",
            name: "Cards Activities",
          },
        ],
      },
    ]);
    const BTN_closeNavigation = ref(null);

    watch(
      () => route.path,
      async () => {
        if (BTN_closeNavigation.value.ariaExpanded === "true") {
          BTN_closeNavigation.value.click();
        }
      }
    );

    const LogoutUser = async () => {
      await store.dispatch(UserActionTypes.Logout);
      await router.push("/auth/login");
    };

    const LockUser = async () => {
      await store.dispatch(UserActionTypes.Lock);
      await router.push("/auth/unlock");
    };

    return {
      LogoutUser,
      LockUser,
      store,
      sideBarItems,
      route,
      BTN_closeNavigation,
    };
  },
});
</script>

<style scoped>
.nav-item {
  cursor: pointer;
}
</style>
