import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import DashboardLayout from "@/layout/DashboardLayout.vue";
import AuthLayout from "@/layout/AuthLayout.vue";
import RenderChildrenLayout from "@/layout/RenderChildrenLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    meta: {
      requiresAuth: true,
    },
    component: DashboardLayout,
    children: [
      {
        path: "",
        redirect: "/overview",
      },
      {
        path: "overview",
        component: () => import("../views/overview/Index.vue"),
      },
      {
        path: "financial-audit",
        component: () => import("../views/audit/FinancialAudit.vue"),
      },
      {
        path: "users",
        component: RenderChildrenLayout,
        children: [
          {
            path: "",
            component: () => import("../views/users/Index.vue"),
          },
          {
            path: ":userId(\\d+)",
            component: () => import("../views/users/UserDetail.vue"),
          },
        ],
      },
      {
        path: "withdrawals",
        component: RenderChildrenLayout,
        children: [
          {
            path: "",
            component: () => import("../views/withdrawals/Index.vue"),
          },
          {
            path: ":userId/:transactionId(\\d+)",
            component: () =>
              import("../views/withdrawals/WithdrawalDetail.vue"),
          },
          {
            path: "coins/:coinWithdrawalId(\\d+)",
            component: () =>
              import("../views/withdrawals/CoinWithdrawalDetail.vue"),
          },
        ],
      },
      {
        path: "notifications",
        component: RenderChildrenLayout,
        children: [
          {
            path: "",
            component: () => import("../views/notification/CreateCampaign.vue"),
          },
          {
            path: "new",
            component: () => import("../views/notification/CreateCampaign.vue"),
          },
        ],
      },
      {
        path: "profile",
        component: RenderChildrenLayout,
        children: [
          {
            path: "",
            component: () => import("../views/profile/Index.vue"),
          },
          {
            path: "2fa",
            component: () => import("../views/profile/2FAuthentication.vue"),
          },
        ],
      },
      {
        path: "cards",
        component: RenderChildrenLayout,
        children: [
          {
            path: "buy-t",
            component: () =>
              import("../views/cards/BuyGiftCardsTransactions.vue"),
          },
          {
            path: "sell-t",
            component: () =>
              import("../views/cards/SellGiftCardsTransactions.vue"),
          },
          {
            path: "available",
            component: () => import("../views/cards/AvailableGiftCards.vue"),
          },
          {
            path: "create",
            component: () => import("../views/cards/CreateGiftCard.vue"),
          },
          {
            path: ":giftCardId(\\d+)/entries",
            component: () => import("../views/cards/GiftCardEntries.vue"),
          },
          {
            path: ":giftCardId(\\d+)/tac",
            component: () => import("../views/cards/GiftCardTAC.vue"),
          },
          {
            path: ":giftCardId(\\d+)/enter",
            component: () => import("../views/cards/CreateGiftCardEntries.vue"),
          },
          {
            path: "sell-t/:transactionId(\\d+)",
            component: () => import("../views/cards/SellGiftCardDetail.vue"),
          },
          {
            path: "buy-t/:transactionId(\\d+)",
            component: () => import("../views/cards/BuyGiftCardDetail.vue"),
          },
          {
            path: "entries/:entryId(\\d+)",
            component: () => import("../views/cards/EditGiftCardEntries.vue"),
          },
        ],
      },
      {
        path: "coins",
        component: RenderChildrenLayout,
        children: [
          {
            path: "coin-types",
            component: () => import("../views/coins/CoinTypes.vue"),
          },
          {
            path: "sell-coins",
            component: () => import("../views/coins/SellCoins.vue"),
          },
          {
            path: "sell-coins/:sellCoinId(\\d+)",
            component: () => import("../views/coins/SellCoinDetail.vue"),
          },
          {
            path: "create",
            component: () => import("../views/coins/CreateCoinType.vue"),
          },
          // {
          //   path: "create-coin-rate",
          //   component: () => import("../views/coins/CreateCoinRate.vue"),
          // },

          {
            path: ":coinId(\\d+)",
            component: () => import("../views/coins/CoinTypeDetail.vue"),
          },
          {
            path: "entries/:coinId(\\d+)",
            component: () => import("../views/coins/EditCoinEntry.vue"),
          },

          {
            path: "coin-rates",
            component: () => import("../views/coins/CoinRates.vue"),
          },
          {
            path: ":coinRateId(\\d+)/entries",
            component: () => import("../views/coins/CoinRateDetails.vue"),
          },
          {
            path: ":coinRateId(\\d+)/enter",
            component: () => import("../views/coins/CreateCoinRate.vue"),
          },

          {
            path: "entries/:coinTypeId/rate/:coinRateId(\\d+)",
            component: () => import("../views/coins/EditCoinRateEntry.vue"),
          },
        ],
      },
      {
        path: "settings",
        component: RenderChildrenLayout,
        children: [
          {
            path: "cards",
            component: () => import("../views/settings/CardsSettings.vue"),
          },
          {
            path: "withdrawals",
            component: () =>
              import("../views/settings/WithdrawalsSettings.vue"),
          },
          {
            path: "responses",
            component: () => import("../views/settings/ResponsesSettings.vue"),
          },

          {
            path: "entries/response/:responseId(\\d+)",
            component: () => import("../views/settings/EditResponseEntry.vue"),
          },
          {
            path: "response/create",
            component: () => import("../views/settings/CreateResponse.vue"),
          },
          {
            path: "kyc",
            component: () => import("../views/settings/KycSettings.vue"),
          },
          {
            path: "kyc/create",
            component: () => import("../views/settings/CreateKyc.vue"),
          },
          {
            path: "entries/kyc/:kycId(\\d+)",
            component: () => import("../views/settings/EditKycSettings.vue"),
          },
        ],
      },
      {
        path: "activities",
        component: RenderChildrenLayout,
        children: [
          {
            path: "activities",
            component: () => import("../views/activities/CardsActivities.vue"),
          },
          {
            path: ":activityId(\\d+)",
            component: () => import("../views/activities/ActivitiesDetail.vue"),
          },
        ],
      },

      {
        path: "currency",
        component: RenderChildrenLayout,
        children: [
          {
            path: "fiat",
            component: () => import("../views/currency/FiatCurrencies.vue"),
          },
          {
            path: "fiat/create",
            component: () => import("../views/currency/CreateFiatCurrency.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    component: AuthLayout,
    meta: {
      redirectIfLoggedIn: true,
    },
    children: [
      {
        path: "",
        redirect: "/auth/login",
      },
      {
        path: "login",
        name: "Login",
        meta: {
          title: "Login to Astropay Admin",
        },
        component: () => import("../views/auth/Login.vue"),
      },
      {
        path: "unlock",
        name: "Unlock",
        meta: {
          title: "Unlock application",
        },
        component: () => import("../views/auth/Unlock.vue"),
      },
      {
        path: "forgot_p",
        name: "ForgotPassword",
        meta: {
          title: "Forgot Password",
        },
        component: () => import("../views/auth/ForgotPassword.vue"),
      },
      {
        path: "reset_p",
        name: "ResetPassword",
        meta: {
          title: "Reset Password",
        },
        component: () => import("../views/auth/ResetPassword.vue"),
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("../views/error/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
