<template>
  <router-view v-slot="{ Component }">
    <transition name="component-fade" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>

<style lang="scss"></style>

<script lang="ts">
// @ is an alias to /src
import { useStore } from "vuex";
import { defineComponent } from "vue";
import { UserActionTypes } from "@/store/modules/user/actions";

export default defineComponent({
  name: "Home",
  components: {},
  setup(): void {
    useStore().dispatch(UserActionTypes.RefreshProfile);
  },
});
</script>
