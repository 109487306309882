<template>
  <main>
    <section class="vh-100 d-flex align-items-center">
      <div class="container">
        <div
          class="row justify-content-center form-bg-image"
          data-background-lg="@/assets/img/illustrations/signin.svg"
        >
          <div class="col-12 d-flex align-items-center justify-content-center">
            <router-view v-slot="{ Component }">
              <transition name="component-fade" mode="out-in">
                <component :is="Component"></component>
              </transition>
            </router-view>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "AuthLayout"
};
</script>

<style lang="scss" scoped>
.form-bg-image {
  background: url("../assets/img/illustrations/signin.svg");
}
</style>
