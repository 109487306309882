import { Directive, DirectiveBinding } from "vue";
import store from "@/store";

const superuser: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const clearance = Number(store.getters.clearance);

    if (isNaN(clearance)) {
      return;
    }

    if (clearance === 100) {
      return;
    }

    if (binding.value && binding.value === clearance) {
      return;
    }

    if (binding.value && binding.value < clearance) {
      return;
    }

    if (!binding.value && clearance >= 3) return;

    el.style.display = "none";
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    const clearance = Number(store.getters.clearance);

    if (isNaN(clearance)) {
      el.style.display = "";
      return;
    }

    if (clearance === 100) {
      el.style.display = "";
      return;
    }

    if (binding.value && binding.value === clearance) {
      el.style.display = "";
      return;
    }

    if (binding.value && binding.value < clearance) {
      el.style.display = "";
      return;
    }

    if (!binding.value && clearance >= 3) {
      el.style.display = "";
      return;
    }

    el.style.display = "none";
  },
};

export default superuser;
