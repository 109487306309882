import { MutationTree } from "vuex";

import type { siteKey } from "./app.d";

import { State } from "./state";

export enum MutationTypes {
  SET_SITE_KEY = "SET_SITE_KEY",
}

export type Mutations<S = State> = {
  [MutationTypes.SET_SITE_KEY](state: S, payload: siteKey): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_SITE_KEY](state: State, siteKey: siteKey) {
    state.siteKey = siteKey;
  },
};
