import { createStore } from "vuex";

import {
  store as app,
  Store as App,
  State as AppState,
} from "@/store/modules/app";

import {
  store as user,
  Store as User,
  State as UserState,
} from "@/store/modules/user";

export type RootState = {
  app: AppState;
  user: UserState;
};

export type Store = App<Pick<RootState, "app">> & User<Pick<RootState, "user">>;

const store = createStore({
  modules: {
    app,
    user,
  },
});

export default store;

export function useStore(): Store {
  return store as Store;
}
