<template>
  <div class="pe-2">
    <button class="btn btn-outline-danger" @click="$router.back()">
      Go Back
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Back",
});
</script>

<style scoped></style>
