import type {
  id,
  token,
  type,
  profile,
  email,
  username,
  expiry,
  clearance,
  details,
} from "./app.d";

export type State = {
  id: id;
  token: token;
  type: type;
  profile: profile;
  email: email;
  username: username;
  details: details;
  expiry: expiry;
  clearance: clearance;
};

const storedProfile = localStorage.getItem("profile");
const storedDetails = localStorage.getItem("details");
const savedProfile = JSON.parse(storedProfile ? storedProfile : "{}");
const savedDetails = JSON.parse(storedDetails ? storedDetails : "{}");
const expiryTime = localStorage.getItem("expiry")
  ? Number(localStorage.expiry)
  : null;
const userClearance = localStorage.getItem("clearance")
  ? Number(localStorage.clearance)
  : null;

export const state: State = {
  id: localStorage.getItem("user_id"),
  token: localStorage.getItem("token"),
  type: localStorage.getItem("type"),
  email: localStorage.getItem("email"),
  username: localStorage.getItem("username"),
  details: savedDetails ? savedDetails : null,
  profile: savedProfile ? savedProfile : null,
  expiry: isNaN(expiryTime ? expiryTime : NaN) ? null : expiryTime,
  clearance: isNaN(userClearance ? userClearance : NaN) ? null : userClearance,
};
