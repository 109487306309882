
// @ is an alias to /src
import { useStore } from "vuex";
import { defineComponent } from "vue";
import { UserActionTypes } from "@/store/modules/user/actions";

export default defineComponent({
  name: "Home",
  components: {},
  setup(): void {
    useStore().dispatch(UserActionTypes.RefreshProfile);
  },
});
