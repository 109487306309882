import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { register } from "@/directives";
import "./interceptor";

import "@popperjs/core/dist/cjs/popper";
import "bootstrap/dist/js/bootstrap.min";

import "./style/index.scss";
import { RequestConfirmation } from "@/utils/notify";

const app = createApp(App).use(store).use(router);
register(app);

app.provide(
  "RequestConfirmation",
  (message: string, ok: () => unknown, err?: () => unknown) => {
    RequestConfirmation(message, ok, err);
  }
);

app.mount("#app");
