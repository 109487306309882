import { ActionTree, ActionContext } from "vuex";

import { RootState } from "@/store";

import { State } from "./state";
import { Mutations, UserMutationTypes } from "./mutations";
import { details, profile } from "@/store/modules/user/app";

export enum UserActionTypes {
  SetID = "SetID",
  SetToken = "SetToken",
  Login = "Login",
  Logout = "Logout",
  Lock = "Lock",
  RefreshProfile = "RefreshProfile",
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [UserActionTypes.SetID](
    { commit }: AugmentedActionContext,
    id: string // Obsolete in here but left as an example
  ): Promise<void>;

  [UserActionTypes.SetToken](
    { commit }: AugmentedActionContext,
    token: string // Obsolete in here but left as an example
  ): Promise<void>;

  [UserActionTypes.Login](
    { commit }: AugmentedActionContext,
    user: Record<string, unknown>
  ): Promise<void>;

  [UserActionTypes.Logout]({ commit }: AugmentedActionContext): Promise<void>;

  [UserActionTypes.Lock]({ commit }: AugmentedActionContext): Promise<void>;

  [UserActionTypes.RefreshProfile]({
    commit,
  }: AugmentedActionContext): Promise<void>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [UserActionTypes.SetID]({ commit }, id: string) {
    commit(UserMutationTypes.SET_ID, id);
  },

  async [UserActionTypes.Login]({ commit }, data: Record<string, unknown>) {
    commit(UserMutationTypes.SET_TOKEN, data.token as string);
    commit(
      UserMutationTypes.SET_ID,
      (data as Record<string, Record<string, never>>).user.id as string
    );
    commit(
      UserMutationTypes.SET_EMAIL,
      (data as Record<string, Record<string, never>>).user.email as string
    );
    commit(
      UserMutationTypes.SET_USERNAME,
      (data as Record<string, Record<string, never>>).user.username as string
    );
    commit(
      UserMutationTypes.SET_TYPE,
      (data as Record<string, Record<string, never>>).user.type as string
    );
    commit(
      UserMutationTypes.SET_CLEARANCE,
      (data as Record<string, Record<string, never>>).user.clearance as number
    );
    commit(
      UserMutationTypes.SET_PROFILE,
      (data as Record<string, Record<string, never>>).user.Profile as profile
    );
    commit(
      UserMutationTypes.SET_DETAILS,
      (data as Record<string, Record<string, never>>).user as details
    );
  },

  async [UserActionTypes.Logout]({ commit }) {
    localStorage.clear();
    commit(UserMutationTypes.SET_TOKEN, null);
    commit(UserMutationTypes.SET_ID, null);
    commit(UserMutationTypes.SET_TYPE, null);
    commit(UserMutationTypes.SET_PROFILE, null);
  },

  async [UserActionTypes.Lock]({ commit }) {
    localStorage.removeItem("token");
    commit(UserMutationTypes.SET_TOKEN, null);
  },

  async [UserActionTypes.SetToken]({ commit }, token: string) {
    commit(UserMutationTypes.SET_TOKEN, token);
  },

  async [UserActionTypes.RefreshProfile]() {
    // const profile = null;
    // commit(UserMutationTypes.SET_PROFILE, profile);
  },
};
