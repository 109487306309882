import { MutationTree } from "vuex";

import type {
  id,
  token,
  type,
  profile,
  email,
  username,
  clearance,
  details,
} from "./app.d";

import { State } from "./state";

export enum UserMutationTypes {
  SET_ID = "SET_ID",
  SET_TOKEN = "SET_TOKEN",
  SET_EMAIL = "SET_EMAIL",
  SET_USERNAME = "SET_USERNAME",
  SET_TYPE = "SET_TYPE",
  SET_CLEARANCE = "SET_CLEARANCE",
  SET_PROFILE = "SET_PROFILE",
  SET_DETAILS = "SET_DETAILS",
}

export type Mutations<S = State> = {
  [UserMutationTypes.SET_ID](state: S, payload: id): void;
  [UserMutationTypes.SET_TOKEN](state: S, payload: token): void;
  [UserMutationTypes.SET_EMAIL](state: S, payload: email): void;
  [UserMutationTypes.SET_USERNAME](state: S, payload: username): void;
  [UserMutationTypes.SET_TYPE](state: S, payload: type): void;
  [UserMutationTypes.SET_CLEARANCE](state: S, payload: clearance): void;
  [UserMutationTypes.SET_PROFILE](state: S, payload: profile): void;
  [UserMutationTypes.SET_DETAILS](state: S, payload: details): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [UserMutationTypes.SET_ID](state: State, id: id) {
    if (id) {
      localStorage.setItem("user_id", id);
    }
    state.id = id;
  },
  [UserMutationTypes.SET_TOKEN](state: State, token: token) {
    if (token) {
      const EXPIRY_IN_SECONDS = 60 * 60 * 24;
      const CURRENT_TIME_IN_SECONDS = Math.round(Date.now() / 1000);
      const expiry = CURRENT_TIME_IN_SECONDS + EXPIRY_IN_SECONDS;
      localStorage.setItem("token", token);
      localStorage.setItem("expiry", expiry.toString());
      state.expiry = expiry;
    } else {
      state.expiry = null;
    }
    state.token = token;
  },
  [UserMutationTypes.SET_EMAIL](state: State, email: email) {
    if (email) {
      localStorage.setItem("email", email);
    }
    state.email = email;
  },
  [UserMutationTypes.SET_USERNAME](state: State, username: username) {
    if (username) {
      localStorage.setItem("username", username);
    }
    state.username = username;
  },
  [UserMutationTypes.SET_TYPE](state: State, type: type) {
    if (type) {
      localStorage.setItem("type", type.toString());
    }
    state.type = type;
  },
  [UserMutationTypes.SET_CLEARANCE](state: State, clearance: clearance) {
    if (clearance) {
      localStorage.setItem("clearance", clearance.toString());
    }
    state.clearance = clearance;
  },
  [UserMutationTypes.SET_PROFILE](state: State, profile: profile) {
    if (profile) {
      localStorage.setItem("profile", JSON.stringify(profile));
    }
    state.profile = profile;
  },
  [UserMutationTypes.SET_DETAILS](state: State, details: details) {
    if (details) {
      localStorage.setItem("details", JSON.stringify(details));
    }
    state.details = details;
  },
};
