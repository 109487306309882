
import DashboardTopNav from "@/layout/DashboardTopNav.vue";
import DashboardSideBar from "@/layout/DashboardSideBar.vue";
import DashboardFooter from "@/layout/DashboardFooter.vue";
// import EmailNotifier from "@/components/dashboard/EmailNotifier";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DashboardLayout",
  components: {
    // EmailNotifier,
    DashboardFooter,
    DashboardSideBar,
    DashboardTopNav,
  },
  setup() {
    return {};
  },
});
