import Swal from "sweetalert2";

export const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const RequestConfirmation = (
  message: string,
  ok: () => unknown,
  err?: () => unknown
): void => {
  Swal.fire({
    title: "Are you sure?",
    text: message,
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Perform action!",
  }).then((result) => {
    if (result.isConfirmed) {
      ok();
    } else {
      if (err) {
        err();
      }
    }
  });
};

export const ShowSuccess = (message?: string, ok?: () => unknown): void => {
  Swal.fire({
    position: "top-end",
    icon: "success",
    title: message ? message : "Successfully Executed",
    showConfirmButton: false,
    timer: 1500,
  }).then(() => {
    if (ok) {
      ok();
    }
  });
};
