<template>
  <main>
    <nav class="navbar navbar-dark navbar-theme-primary px-4 col-12 d-md-none">
      <router-link class="navbar-brand mr-lg-5" to="/">
        <img
          class="navbar-brand-dark"
          src="@/assets/logo_mini.png"
          alt="Volt logo"
        />
      </router-link>
      <div class="d-flex align-items-center">
        <button
          class="navbar-toggler d-md-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>

    <div class="container-fluid bg-soft">
      <div class="row">
        <div class="col-12">
          <DashboardSideBar />

          <main class="content">
            <DashboardTopNav />
            <!--            <EmailNotifier v-if="false" class="col-12 mt-4" />-->
            <router-view v-slot="{ Component }">
              <transition name="component-fade" mode="out-in">
                <keep-alive :key="$route.fullPath">
                  <component :is="Component"></component>
                </keep-alive>
              </transition>
            </router-view>
            <!--          Footer -->
            <DashboardFooter />
          </main>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import DashboardTopNav from "@/layout/DashboardTopNav.vue";
import DashboardSideBar from "@/layout/DashboardSideBar.vue";
import DashboardFooter from "@/layout/DashboardFooter.vue";
// import EmailNotifier from "@/components/dashboard/EmailNotifier";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DashboardLayout",
  components: {
    // EmailNotifier,
    DashboardFooter,
    DashboardSideBar,
    DashboardTopNav,
  },
  setup() {
    return {};
  },
});
</script>

<style></style>
