import { GetterTree } from "vuex";

import type {
  id,
  token,
  type,
  profile,
  email,
  clearance,
  details,
} from "./app.d";

import { RootState } from "@/store";

import { State } from "./state";

export type Getters = {
  id(state: State): id;
  token(state: State): token;
  email(state: State): email;
  type(state: State): type;
  clearance(state: State): clearance;
  details(state: State): details;
  profile(state: State): profile;
  userIsLoggedIn(state: State): boolean;
  userIsExpired(state: State): boolean;
  userIsLocked(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  id: (state) => state.id,
  token: (state) => state.token,
  type: (state) => state.type,
  profile: (state) => state.profile,
  email: (state) => state.email,
  clearance: (state) => state.clearance,
  details: (state) => state.details,
  userIsLocked: (state) => (state.id ? state.token === null : false),
  userIsExpired: (state) =>
    state.expiry ? new Date().getTime() / 1000 > state.expiry : false,
  userIsLoggedIn: (state) => !!state.id,
};
