import type { siteKey, flwPubKey, locked } from "./app.d";

export type State = {
  siteKey: siteKey;
  flwPubKey: flwPubKey;
  locked: locked;
};

export const state: State = {
  siteKey: "6LdmdfwUAAAAAIURbJE3MD4L7Bf7HXlkucTUfYZt",
  flwPubKey: "FLWPUBK-2e180d3fd12cd2e23875fdf81174fa2f-X",
  locked: true,
};
