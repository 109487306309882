import "nprogress/nprogress.css"; // progress bar style
import NProgress from "nprogress"; // progress bar

import router from "@/router";
import store from "@/store";

import { changePageTitle } from "./utils";
import { UserActionTypes } from "@/store/modules/user/actions";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { UserMutationTypes } from "@/store/modules/user/mutations";

function goToNextPage(to: RouteLocationNormalized, next: NavigationGuardNext) {
  changePageTitle(to?.meta?.title as string);
  next();
}

NProgress.configure({ showSpinner: true }); // NProgress Configuration

router.beforeEach((to, from, next) => {
  NProgress.start(); // start progress bar

  const authIsRequired = to.matched.some((route) => route.meta?.requiresAuth);

  if (!authIsRequired) {
    goToNextPage(to, next);
    return;
  }

  if (!store.getters.userIsLoggedIn) {
    next({
      path: "/auth/login",
      query: { redirect: to.fullPath },
    });
    return;
  }

  if (store.getters.userIsLocked) {
    console.log("Locked");
    next({
      path: "/auth/unlock",
      query: { redirect: to.fullPath },
    });
    return;
  }

  if (store.getters.userIsExpired) {
    console.log("Session Expired");
    store.commit(UserMutationTypes.SET_TOKEN, null);
    next({
      path: "/auth/unlock",
      query: { redirect: to.fullPath },
    });
    return;
  }

  goToNextPage(to, next);
});

router.afterEach((to, from, next) => {
  if (store.getters.userIsLoggedIn && !store.state.user.id) {
    store.dispatch(UserActionTypes.RefreshProfile).then();
  }
  changePageTitle(to.meta ? (to.meta.title as string) : null);
  NProgress.done(); // finish progress bar
});
