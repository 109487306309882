<template>
  <nav
    class="navbar navbar-top navbar-expand navbar-dashboard navbar-dark pl-0 pr-2 pb-0"
  >
    <div class="container-fluid px-0">
      <div
        class="d-flex justify-content-between w-100"
        id="navbarSupportedContent"
      >
        <div class="d-flex">
          <!-- Search form -->
          <Back />
          <form
            class="navbar-search form-inline"
            id="navbar-search-main"
            @submit.prevent=""
          >
            <div class="input-group input-group-merge search-bar">
              <span class="input-group-text" id="topbar-addon"
                ><span class="fas fa-search"></span
              ></span>
              <input
                type="text"
                class="form-control px-1"
                id="topbarInputIconLeft"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="topbar-addon"
              />
            </div>
          </form>
        </div>
        <!-- Navbar links -->
        <ul class="navbar-nav align-items-center">
          <li class="nav-item dropdown dropstart">
            <a
              class="nav-link pt-1 px-0"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="media d-flex align-items-center">
                <span class="fas fa-user fa-2x text-black"></span>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-start mt-2">
              <router-link class="dropdown-item font-weight-bold" to="/profile">
                <span class="far fa-user-circle"></span>
                My Profile
              </router-link>
              <div role="separator" class="dropdown-divider"></div>
              <a
                class="dropdown-item font-weight-bold"
                href="javascript:void(0)"
                @click="LogoutUser"
              >
                <span class="fas fa-sign-out-alt text-danger"></span>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { defineComponent } from "vue";
import { UserActionTypes } from "@/store/modules/user/actions";
import Back from "@/components/Back.vue";

export default defineComponent({
  name: "DashboardTopNav",
  components: { Back },
  setup() {
    const store = useStore();
    const router = useRouter();

    const LogoutUser = async () => {
      await store.dispatch(UserActionTypes.Logout);
      await router.push("/auth/login");
    };

    return {
      LogoutUser,
    };
  },
});
</script>

<style scoped></style>
