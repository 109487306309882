<template>
  <footer class="py-5">
    <div class="d-flex flex-column flex-lg-row justify-content-lg-between">
      <div
        class="text-black-50 text-center d-flex flex-column justify-content-center"
      >
        <h6 class="fw-normal copyright-text">
          ASTROAFRICA. {{ new Date().getFullYear() }} &copy; All Copyright
          Reserved
        </h6>
      </div>
      <div>
        <nav class="nav">
          <a
            href="https://astroafrica.site"
            target="_blank"
            class="nav-link text-black-50"
            >Astroafrica</a
          >
          <a
            href="https://astroafrica.site/about"
            class="nav-link text-black-50"
            >About</a
          >
          <a href="https://astroafrica.site/tac" class="nav-link text-black-50"
            >T & C</a
          >
          <a
            href="https://astroafrica.site/privacy"
            class="nav-link text-black-50"
            >Privacy</a
          >
          <a
            href="https://astroafrica.site/contact"
            class="nav-link text-black-50"
            >Contact</a
          >
        </nav>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "DashboardFooter",
};
</script>

<style scoped></style>
