
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { defineComponent } from "vue";
import { UserActionTypes } from "@/store/modules/user/actions";
import Back from "@/components/Back.vue";

export default defineComponent({
  name: "DashboardTopNav",
  components: { Back },
  setup() {
    const store = useStore();
    const router = useRouter();

    const LogoutUser = async () => {
      await store.dispatch(UserActionTypes.Logout);
      await router.push("/auth/login");
    };

    return {
      LogoutUser,
    };
  },
});
