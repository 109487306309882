import { ActionTree } from "vuex";

import { RootState } from "@/store";

import { State } from "./state";

export enum ActionTypes {
  NO_ACTION = "NO_ACTION",
}

export interface Actions {
  [ActionTypes.NO_ACTION](): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  [ActionTypes.NO_ACTION]() {},
};
