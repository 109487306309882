import { Directive, DirectiveBinding } from "vue";

const active: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value) {
      el.classList.add("active");
    } else {
      el.classList.remove("active");
    }
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value) {
      el.classList.add("active");
    } else {
      el.classList.remove("active");
    }
  },
};

export default active;
