import { GetterTree } from "vuex";

import type { siteKey, flwPubKey, locked } from "./app.d";

import { RootState } from "@/store";

import { State } from "./state";

export type Getters = {
  siteKey(state: State): siteKey;
  flwPubKey(state: State): flwPubKey;
  isLocked(state: State): locked;
};

export const getters: GetterTree<State, RootState> & Getters = {
  siteKey: (state) => state.siteKey,
  flwPubKey: (state) => state.flwPubKey,
  isLocked: (state) => state.locked,
};
